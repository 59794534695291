import httpClient from '../httpClient/httpClient';

const ClinicEndpointsStatisticsService = {
  getClinicEndpointsStatistic(clinicID) {
    return httpClient.get('api/get-clinic-endpoints-statistic', {
      params: {
        clinicID,
      },
    });
  },
};

export default ClinicEndpointsStatisticsService;
