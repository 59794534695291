<template>
  <v-container v-if="hasPermission" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <mex-heading content="Endpoints - Clinic Statistics" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="1"></v-col>
      <v-col cols="5">
        <v-autocomplete
          v-model="selectedOrganization"
          :items="organizationNames"
          color="primaryAccent"
          dense
          hide-details
          label="Organization"
          outlined
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.text }}</v-list-item-title>
              <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="5">
        <v-autocomplete
          v-model="selectedClinic"
          :items="clinicNames"
          :readonly="!selectedOrganization"
          color="primaryAccent"
          dense
          hide-details
          label="Clinic"
          outlined
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.text }}</v-list-item-title>
              <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col align-self="center" cols="1">
        <mex-btn icon="mdi-refresh" iconOnly @click="fetchEndpointsStatistic" />
      </v-col>
    </v-row>
    <v-row v-if="!selectedOrganization && !selectedClinic" justify="center">
      <mex-p content="Select an organization" fontSize="overline" />
    </v-row>
    <v-row v-if="selectedOrganization && !selectedClinic" justify="center">
      <mex-p content="Select a clinic" fontSize="overline" />
    </v-row>
    <template v-if="selectedOrganization && selectedClinic">
      <v-row justify="center">
        <v-col cols="10">
          <mex-sheet color="foreground" rounded>
            <v-row justify="start">
              <mex-p content="Endpoints Call Count (Server)" fontSize="h5" fontWeight="bold-italic" />
            </v-row>
            <v-row justify="center">
              <v-col cols="12">
                <license-server-chart v-if="srvCallCountLine" :chartData="srvCallCountLine" />
              </v-col>
            </v-row>
          </mex-sheet>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10">
          <mex-sheet color="foreground" rounded>
            <v-row justify="start">
              <mex-p content="Endpoints Call Count (Client)" fontSize="h5" fontWeight="bold-italic" />
            </v-row>
            <v-row justify="center">
              <v-col cols="12">
                <license-server-chart v-if="cliCallCountLine" :chartData="cliCallCountLine" />
              </v-col>
            </v-row>
          </mex-sheet>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="5">
          <mex-sheet color="foreground" rounded>
            <v-row justify="start">
              <mex-p content="Endpoints Call Count (Server) Summary" fontSize="h5" fontWeight="bold-italic" />
            </v-row>
            <v-row justify="center">
              <v-col cols="12">
                <license-server-chart v-if="srvCallCountPie" :chartData="srvCallCountPie" />
              </v-col>
            </v-row>
          </mex-sheet>
        </v-col>
        <v-col cols="5">
          <mex-sheet color="foreground" rounded>
            <v-row justify="start">
              <mex-p content="Endpoints Call Count (Client) Summary" fontSize="h5" fontWeight="bold-italic" />
            </v-row>
            <v-row justify="center">
              <v-col cols="12">
                <license-server-chart v-if="cliCallCountPie" :chartData="cliCallCountPie" />
              </v-col>
            </v-row>
          </mex-sheet>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ClinicsService from '../../services/clinics.service';
import OrganizationsService from '../../services/organizations.service';
import LicenseServerChart from '../../components/LicSrvComponents/LicenseServerChart.vue';
import ClinicEndpointsStatisticsService from '../../services/clinicEndpointsStatistics.service';
import requiredPermissions from '../../requiredPermissions';
import {assignSeveralProperties} from "../../functions/assignPropertyIfExists";

export default {
  name: 'ClinicEndpointsStatistics',
  components: { LicenseServerChart },
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    getSelectedClinic() {
      return this.selectedClinic;
    },
  },
  data() {
    return {
      // visualization
      editMode: false,
      showSaveRequest: false,
      // selection data
      selectedOrganization: null,
      selectedClinic: null,
      organizationNames: [],
      clinicNames: [],
      preSelection: {
        clinic: null,
        organization: null,
      },
      srvCallCountLine: null,
      cliCallCountLine: null,
      srvCallCountPie: null,
      cliCallCountPie: null,
      hasPermission: false,
    };
  },
  watch: {
    selectedOrganization: {
      handler() {
        this.fetchClinicNames();
        this.$store.commit('selectedProperties/setClinicEndpointsStatisticsOrganization', this.selectedOrganization);
      },
    },
    selectedClinic: {
      handler() {
        this.fetchEndpointsStatistic();
        this.$store.commit('selectedProperties/setClinicEndpointsStatisticsClinic', this.selectedClinic);
      },
    },
  },
  created() {
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.endpointsStatistics, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          this.fetchOrganizationNames();
          assignSeveralProperties([this.$route.params, this.$route.query], ['organization', 'clinic'], this.preSelection, this.$store, 'clinicEndpointsStatistics');
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      })
  },
  methods: {
    fetchOrganizationNames() {
      if (this.organizationNames.length === 0) {
        OrganizationsService.getOrganizationNames()
          .then((organizationResponse) => {
            organizationResponse.data.forEach((orga) => {
              this.organizationNames.push({
                value: orga.OrganizationID,
                text: orga.name,
              });
            });
            if (this.preSelection.organization) {
              this.selectedOrganization = this.preSelection.organization;
              this.preSelection.organization = null;
            } else {
              this.selectedOrganization = null;
            }
          })
          .catch((err) => {
            this.$toast.error(err);
          });
      }
    },
    fetchClinicNames() {
      this.clinicNames = [];
      if (this.selectedOrganization) {
        ClinicsService.getClinicNames(this.selectedOrganization)
          .then((clinicResponse) => {
            clinicResponse.data.forEach((clinic) => {
              this.clinicNames.push({
                value: clinic.ClinicID,
                text: clinic.name,
              });
            });
            if (this.preSelection.clinic) {
              this.selectedClinic = this.preSelection.clinic;
              this.preSelection.clinic = null;
            } else {
              this.selectedClinic = null;
            }
          })
          .catch((err) => {
            this.$toast.error(err);
          });
      }
    },
    fetchEndpointsStatistic() {
      ClinicEndpointsStatisticsService.getClinicEndpointsStatistic(this.selectedClinic).then((response) => {
        this.srvCallCountLine = {
          type: 'line',
          data: {
            labels: response.data.srvCallCountLine.labels,
            datasets: response.data.srvCallCountLine.datasets,
          },
        };
        this.cliCallCountLine = {
          type: 'line',
          data: {
            labels: response.data.cliCallCountLine.labels,
            datasets: response.data.cliCallCountLine.datasets,
          },
        };
        this.srvCallCountPie = {
          type: 'pie',
          data: {
            labels: response.data.srvCallCountPie.labels,
            datasets: response.data.srvCallCountPie.datasets,
          },
        };
        this.cliCallCountPie = {
          type: 'pie',
          data: {
            labels: response.data.cliCallCountPie.labels,
            datasets: response.data.cliCallCountPie.datasets,
          },
        };
      });
    },
  },
};
</script>

<style>
.ct-grids line {
  stroke: whitesmoke !important;
}

.ct-labels span {
  color: steelblue;
}
</style>
